<template>
  <v-menu offset-y rounded>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
        @click="e => $emit('click', e)"
        small
        :disabled="getDisabled()"
      >
        {{ getDisplayTextValue() }}
      </v-btn>
    </template>
    <v-card flat>
      <!-- multiple -->
      <v-btn-toggle
        v-model="objdata.inputpatternindex"
        dense
        group
        multiple
        class="d-block pattern-back"
        @change="change"
      >
        <!-- Button and Tooltip -->
        <v-tooltip v-for="(item, i) in Items" :key="i" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-1" icon v-bind="attrs" v-on="on">
              <span :class="textColor ? textColor + '--text' : ''">
                {{ getText(item) }}</span
              >
            </v-btn>
          </template>
          <span>{{ getToolTip(item) }}</span>
        </v-tooltip>
        <!-- v-for End -->
      </v-btn-toggle>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: Array,
    items: Array,
    // 表示するテキストのキーを設定する場合に使用する。
    textValue: String,
    // value にセットする値が表示テキスト以外にある場合に使用する。
    itemValue: String,
    // ツールチップとして表示するテキストのキーを設定する場合に使用する。
    tooltipValue: String,
    // テキストの初期値を設定する場合に使用する。
    defaultText: String,
    color: String,
    avatarColor: String,
    textColor: String,
    objdata: Object,
    disabled: Boolean
  },
  data: () => ({
    multi: [],
    selectedValues: [],
    setText: ""
  }),
  // data() {
  //   return {
  //     multi: [],
  //     setText: ""
  //   };
  // },
  computed: {
    Items() {
      return this.items;
    }
  },
  watch: {
    multi() {
      this.$emit("input", this.multi);
    }
  },
  methods: {
    getDisabled() {
      return this.disabled;
    },
    getDisplayTextValue() {
      return this.objdata.pattern;
    },
    change(e) {
      console.log("change", e);
      const selectIndex = e[e.length - 1];

      const setInputIndex = new Set(this.objdata.inputpatternindex);
      this.objdata.inputpatternindex = Array.from(setInputIndex);

      if (selectIndex == 0) {
        this.objdata.inputpatternindex = [selectIndex];
      } else {
        this.objdata.inputpatternindex = this.objdata.inputpatternindex.filter(
          e => String(e) !== "0"
        );
      }
      this.objdata.inputpatternindex.sort((a, b) => a - b);
      this.multi = this.objdata.inputpatternindex;
      // this.objdata.inputpatternindex = this.multi;
      const ptn = [];
      for (const index of this.multi) {
        ptn.push(this.getText(this.Items[index]));
      }
      this.objdata.pattern = ptn.join(",");
    },
    getText(item) {
      return this.textValue && item[this.textValue]
        ? item[this.textValue]
        : item.text;
    },
    getItemValue(item) {
      if (!item) return "";
      return this.itemValue ? item[this.itemValue] : item;
    },
    getToolTip(item) {
      return item.tooltip ? item.tooltip : item[this.tooltipValue];
    },
    getColor(item) {
      let color = "";
      switch (item.type) {
        case "break":
          color = "success";
          break;
        case "harf":
          color = "info";
          break;
        default:
          color = this.avatarColor;
          break;
      }

      return color;
    }
  },
  created() {
    if (this.value && this.value) {
      this.data = [...this.value];
    }
  }
};
</script>
<style lang="scss" scoped>
.v-btn-toggle--group > .v-btn.v-btn {
  background-color: #006666 !important;
  min-width: 44px;
  min-height: 44px;
  width: 100px;
}
.pattern-back {
  background-color: #f2f2f2 !important;
}

.theme--light.v-btn.v-btn--disabled {
  color: #ff3399 !important;
}
</style>
