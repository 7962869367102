import { render, staticRenderFns } from "./WelfareShiftTable.vue?vue&type=template&id=2b36baa0&scoped=true&"
import script from "./WelfareShiftTable.vue?vue&type=script&lang=js&"
export * from "./WelfareShiftTable.vue?vue&type=script&lang=js&"
import style1 from "./WelfareShiftTable.vue?vue&type=style&index=1&id=2b36baa0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b36baa0",
  null
  
)

export default component.exports